import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DateRangeType, IgxCalendarComponent } from 'igniteui-angular';
import * as moment from 'moment';


@Component({
  selector: 'app-calendar-reservation',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  @ViewChild('calendar', { read: IgxCalendarComponent }) public calendar: IgxCalendarComponent;

  @Input() range = [];

  @Input() reservedDates = [];

  @Output() DateChanged: EventEmitter<any> = new EventEmitter();


  public formatOptions: any;
  public formatViews: any;
  public locale = 'FR';

  disabledDate: any;

  message = '';

  constructor(
  ) { }

  ngOnInit(): void {

    // const currentDate: moment.Moment = moment();
    let ListReservation = [];

    this.reservedDates.forEach(element => {
      ListReservation.push(new Date(element));
    });

    const todayDate = new Date();

    this.disabledDate = [{ type: DateRangeType.Specific, dateRange: ListReservation }];
    this.disabledDate.push({ type: DateRangeType.Before, dateRange: [this.range[0]] });
    this.disabledDate.push({ type: DateRangeType.After, dateRange: [this.range[1]] });
    this.disabledDate.push({ type: DateRangeType.Before, dateRange: [todayDate] });

    this.formatOptions = { day: '2-digit', month: 'long', weekday: 'short', year: 'numeric' };
    this.formatViews = { day: true, month: true, year: true };
  }


  public selectPTOdays(dates: Date[]) {
    let validInterval = true;

    this.message = '';

    if (dates.length > 1) {

      this.reservedDates.forEach(element => {
        if (this.getRange(element, dates[0], dates[dates.length - 1])) {
          validInterval = false;
        }
      });

    } else {
      validInterval = true;
    }
    
    if (dates.length > 0) {
      if (validInterval) {
        this.message = '';
        this.DateChanged.emit({ validInterval, dates });
      } else {
        this.DateChanged.emit({ validInterval: false, dates: [] });
        this.message = 'Les dates sélectionnées comportent des dates déjà réservées';
      }
    } else {
      this.message = '';
      this.DateChanged.emit({ validInterval: false, dates: [] });
    }

  }



  getRange(dateToCompare, startDate, endDate) {

    const Date = moment(dateToCompare);
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    return Date.isBetween(fromDate, toDate);

  }

}
