// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const baseOrigine = 'https://api.lokatout.fr/';

export const environment = {
  Api_Url: baseOrigine,
  recaptchaSiteKey:"6LemMu4aAAAAACu_y2JDhXbjrZW5BWMKLFiFp86K",
  apiKey:'AIzaSyD_6Huo71VIvAaAHGTRHdzDVD655ZiJVvg',
  stripePkey : 'pk_live_51JhcsuERVwXxtCZueBTYUNu0YUnDV65w3Mfubq8m0olKIXsGRN3IlpCtF4Gj6WXvrYPsuqwonidpEf4vNZZV49oz00rzD9mA8W',
  production: true,
  annonceMedia: baseOrigine + 'media/rentalPictures/',
  googleClient: '222104909341-th7evt11qiii3845a6ru83i2i8qh7dps.apps.googleusercontent.com',
  facebookClient: '625078845580463',
  showRobots: true,
};