import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  url = environment.Api_Url;
  constructor(private http: HttpClient) { }

  updateUser(object) {
    return new Promise(resolve => {
      this.http.patch(this.url + 'users/profile', object,
        {
          headers: { Authorization: 'true' }
        }).subscribe((res: any) => {
          resolve({ status: true, data: res });
        }, (err) => {
          resolve({ status: false, error: err });
        });
    });
  }


  sendVerification(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'verify-email/send', data,
        {
          headers: { Authorization: 'true' }
        }).subscribe((res: any) => {
          resolve({ status: true, data: res });
        }, (err) => {
          resolve({ status: false, error: err });
        });
    });
  }

  verifyEmail(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'verify-email', data,
        {
          headers: { Authorization: 'true' }
        }).subscribe((res: any) => {
          resolve({ status: true, data: res });
        }, (err) => {
          resolve({ status: false, error: err });
        });
    });
  }


  getManagerCount() {

    return new Promise(resolve => {
      this.http.get(this.url + 'configs/counts',
        {
          headers: { Authorization: 'true' }
        }).subscribe((res: any) => {
          resolve({ status: true, data: res });
        }, (err) => {
          resolve({ status: false, error: err });
        });
    });
  }

  getNotifications() {
    return new Promise(resolve => {
      this.http.get(this.url + 'notifications',
        {
          headers: { Authorization: 'true' }
        }).subscribe((res: any) => {
          resolve({ status: true, data: res });
        }, (err) => {
          resolve({ status: false, error: err });
        });
    });
  }



}
