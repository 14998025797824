import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeService, StripeCardComponent, } from 'ngx-stripe';
import { StripeCardElementOptions, StripeCardElement, StripeElementsOptions, Stripe } from '@stripe/stripe-js';
import { NgbDate, NgbCalendar, NgbModal, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { StripeSecureAuthComponent } from '../stripe-secure-auth/stripe-secure-auth.component';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Output() PaymentStatus: EventEmitter<any> = new EventEmitter();


  @Input() price;

  @Input() totalDays = 0;
  @Input() totalMonths = 0;
  @Input() totalHours = 0;
  @Input() feePrice = 0;


  @Input() currentUser;
  @Input() adver;
  @Input() reservation;

  @Input() beginDate;
  @Input() endDate;

  @Input() totalPrice: any = 0;


  paymentMethods: any = [];

  selectedCard = '';
  paymentIntentDetails: any = [];
  step = 1;

  webviewUrl = '';

  loading = false;
  UseNewCard = false;
  saveCard = false;
  error = false;
  loadingPayement = false;

  message = '';

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'fr'
  };

  stripeForm: FormGroup;

  constructor(
    private paymentServ: PaymentService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private stripeServ: StripeService,
    private locatServ: LocationService,
    public authServ: AuthService,
    protected _sanitizer: DomSanitizer,
    config: NgbModalConfig,
  ) {

    config.backdrop = 'static';
    config.keyboard = false;

  }

  ngOnInit(): void {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.getPaymentMethods();
    this.getConnectedUser();
    this.totalPrice = Number(this.reservation.totalPrice).toFixed(2);
  }

  getConnectedUser() {
    this.loading = true;
    this.authServ.getConnectedUser().then(
      (resp: any) => {
        if (resp.status) {
          this.currentUser = resp.data.data.user;
          this.loading = false;
        } else {
          this.loading = false;
        }
      }
    );
  }



  getPaymentMethods() {
    this.loading = true;
    this.paymentServ.getMethods().then(

      (resp: any) => {
        if (resp.status) {
          this.paymentMethods = resp.data.data.paymentMethods;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {

      }

    );
  }


  close() {
    this.modalService.dismissAll();
  }

  changeSelectedCard() {
  }


  /// with saved Cards;
  Checkout() {
    this.loadingPayement = true;
    const data = {
      description: 'Paiement stripe ' + this.adver?.title,
      amount: (this.totalPrice * 100).toFixed(0),
      ...((this.selectedCard) && { paymentMethod: this.selectedCard }),
    };

    this.paymentServ.creatPaymentIntent(data).then(
      (resp: any) => {
        if (resp.status) {

          this.paymentIntentDetails = resp.data?.data;
          // this.createToken(this.selectedCard);
          this.loadingPayement = false;
          this.error = false;


          console.log(this.paymentIntentDetails);

          if (this.paymentIntentDetails.paymentIntent.status === 'requires_action') {

            this.webviewUrl = this.paymentIntentDetails?.paymentIntent?.next_action?.use_stripe_sdk?.stripe_js;


            const modalReference = this.modalService.open(
              StripeSecureAuthComponent,
              { backdrop: 'static', size: 'l', keyboard: false, centered: true, windowClass: 'location-modal' }
            );
            modalReference.componentInstance.webviewUrl = this.webviewUrl;

            modalReference.componentInstance['closeAction'].subscribe(event => {

              event ? this.confirmPayment() : '';
              modalReference.close();
            });



          } else {
            this.confirmPayment();
          }


        } else {
          this.error = true;
          this.loadingPayement = false;
        }
      },
      (error: any) => {
        this.error = true;
        this.loadingPayement = false;
      }
    );
  }


  /// with new Card

  createPaymentIntent(): void {


    this.loadingPayement = true;
    const data = {
      amount: (this.totalPrice * 100).toFixed(0),
      description: 'Paiement stripe ' + this.adver?.title,
      email: this.currentUser?.email,
      name: this.currentUser?.profile?.firstName,
      saveCard: this.saveCard,
    };

    this.paymentServ.creatPaymentIntent(data).then(

      (resp: any) => {
        if (resp.status) {
          this.paymentIntentDetails = resp.data?.data;
          this.createToken();
        } else {
          this.loadingPayement = false;
          this.error = true;
        }
      },
      (error: any) => {
        this.error = true;
      }

    );
  }

  confirmPayment() {

    this.loadingPayement = true;
    const dataPayment = {
      paymentId: this.paymentIntentDetails.paymentIntent.id,
      paymentAmount: (this.totalPrice * 100).toFixed(0),
      paymentMethod: this.selectedCard,
    };


    this.locatServ.confirmPayment(this.reservation._id, dataPayment).then(
      (response: any) => {
        if (response.status) {
          this.step = 2;
          this.PaymentStatus.emit(true);
          this.loadingPayement = false;
        } else {
          this.loadingPayement = false;
          this.error = true;
          this.PaymentStatus.emit(false);
        }
      },
      (error: any) => {
        this.error = true;
        this.loadingPayement = false;
        this.PaymentStatus.emit(false);
      }
    );

  }

  createToken() {
    this.loadingPayement = true;
    this.stripeServ.confirmCardPayment(
      this.paymentIntentDetails?.paymentIntentSecret,
      {
        payment_method: { card: this.card.element }
      }
    ).subscribe(
      (resp: any) => {
        if (resp.error) {
          this.loadingPayement = false;
          this.error = true;
          this.message = 'Veuillez vérifier les informations de votre carte';
        } else {
          this.loadingPayement = false;
          this.error = false;
          this.confirmPayment();
        }
      });

  }



}
