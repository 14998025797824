import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  url = environment.Api_Url;


  constructor(private http: HttpClient) { }


  creatPaymentIntent(data) {


    return new Promise(resolve => {
      this.http.post(this.url + 'payments', data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }


  getMethods() {
    return new Promise(resolve => {
      this.http.get(this.url + 'payments/payment-methods',
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getPeriodPrice(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'reservations/period-price', data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getUserTransaction(limit = 10, startingAfter = '', startingBefor = '') {
    return new Promise(resolve => {
      this.http.get(this.url + 'payments/user/transactions?limit=' + limit
        + '&starting_before=' + startingBefor
        + '&starting_after=' + startingAfter,
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }
  getUserWallet() {
    return new Promise(resolve => {
      this.http.get(this.url + 'payments/customers/me',
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getConnectAccount() {
    return new Promise(resolve => {
      this.http.get(this.url + 'payments/accounts/me',
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  generateVerifyLink() {
    return new Promise(resolve => {
      this.http.get(this.url + 'payments/accounts/link',
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  addTransactionRequest(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'transactions', data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getMytransactionRequest(offset = 0, limit = 5) {
    return new Promise(resolve => {
      this.http.get(this.url + 'transactions/me/' + offset + '/' + limit,
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }
}
