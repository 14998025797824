import { Component, NgZone, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { GoogleMapsAPIWrapper, AgmMap, } from '@agm/core';


@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.scss']
})
export class ViewMapComponent implements OnInit {


  @ViewChild('AgmMap') agmMap: AgmMap;


  @Output() ZoomChanged: EventEmitter<any> = new EventEmitter();
  @Output() centerChanged: EventEmitter<any> = new EventEmitter();

  @Input() lalt: any;
  @Input() long: any;


  @Input() lalt2: any;

  @Input() addressList: any;

  @Input() long2: any;
  @Input() zoom: any = 10;

  @Input() showDirection = false;
  @Input() is_List = false;

  @Input() address = "";

  defaultlatitude: 48.8708001;
  defaultlongitude: 2.166848;

  drivingOptions: any = undefined;

  origin: any;
  destination: any;
  waypoints: any;

  markerurl: any;
  constructor() { }

  ngOnInit(): void {
    this.origin = { lat: this.lalt, lng: this.long };
    this.destination = { lat: this.lalt2, lng: this.long2 };

    this.markerurl = {
      url: 'assets/img/svg/Logo.svg',
      scaledSize: {
        width: 40,
        height: 60
      }
    };

  }



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.agmMap.triggerResize();
    }, 100);
  }


  zoomChange(event) {

    let km = ((40000 / Math.pow(2, event)) * 2).toFixed(2);

    const data = {
      km,
      zoom: event,
    };

    this.ZoomChanged.emit(data);
  }

  centerChange(event) {
    const distance = this.getDistanceFromLatLonInKm(this.lalt, this.long, event.lat, event.lng);
    if (distance > 2) {
      this.centerChanged.emit(event);
    }
  }



  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

}
