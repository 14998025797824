import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninGuard } from './core/guard/signin/signin.guard';
import { VerificationEmailComponent } from './shared/verification-email/verification-email.component';


const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./front/front.module').then(m => m.FrontModule),

  },
  { path: 'page/verification-email', component: VerificationEmailComponent },
  {
    path: 'annonces',
    loadChildren: () => import('./annonces/annonces.module').then(m => m.AnnoncesModule),
  },
  {
    path: 'page',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [SigninGuard]
  },

  {
    path: '**',
    redirectTo: 'page/404'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
