import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { NgbDate, NgbCalendar, NgbModal, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stripe-secure-auth',
  templateUrl: './stripe-secure-auth.component.html',
  styleUrls: ['./stripe-secure-auth.component.scss']
})
export class StripeSecureAuthComponent implements OnInit {


  @Input() webviewUrl = '';
  @Output() PaymentStatus: EventEmitter<any> = new EventEmitter();
  @Output() closeAction: EventEmitter<any> = new EventEmitter();

  loadingPayement = false;
  loading = false;

  constructor(
    protected _sanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

    console.log(this.webviewUrl);

    setTimeout(() => {
      window.open(this.webviewUrl, '_blank').focus();
    }, 2000);

  }

  close(event) {
    this.closeAction.emit(event);
  }

}
