import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LocationService {


  url = environment.Api_Url;

  constructor(private http: HttpClient) { }



  creatReservation(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'reservations', data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  getMyRents(offset, limit) {
    return new Promise(resolve => {
      this.http.get(this.url + 'reservations/user/' + offset + '/' + limit,
        {
          headers: { Authorization: 'true' }
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }





  deleteRent(id) {

    return new Promise(resolve => {
      this.http.delete(this.url + 'reservations/' + id,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }


  updateOne(id, data) {

    return new Promise(resolve => {
      this.http.patch(this.url + 'reservations/' + id, data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, response: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }


  confirmPayment(id, data) {

    return new Promise(resolve => {
      this.http.patch(this.url + 'reservations/' + id + '/confirm', data,
        {
          headers: { Authorization: 'true' },
          observe: 'response'
        }
      ).subscribe((res: any) => {
        resolve({ status: true, response: res.body });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }



}
