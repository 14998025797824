<ngx-slick-carousel class="carousel" [config]="SlideMenuConfig">
    <div class="mr-2 ml-2 mt-2 mb-2" ngxSlickItem *ngFor="let category of categories">
      <lokatout-category-box [category]="category"></lokatout-category-box>
    </div>
    <div class="mr-2 ml-2 mt-2 mb-2" ngxSlickItem>

      <div class="category-item">
        <div class="category-box">
          <div class="category-cover">
            <a href="javascript:void(0)">
              <div [style.backgroundImage]="'url(/assets/img/sky-category.jpeg)'"
                class="img-fluid full-width"></div>
            </a>
          </div>
          <div class="category-caption">
            <a href="https://gosportmontagne.com/partner/lokatout" target="_blank">
              <div class="title-box">
                <h6 class="category-title"> Ski </h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
