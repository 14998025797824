import { Component, NgZone, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, Params } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { CategoryService } from 'src/app/services/category.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'lokatout-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {


  requests = false;
  showCategories = false;
  keyWords = '';
  place: any = [];
  address = '';
  selectedCategory: any;

  url = environment.Api_Url;


  @Output() PlaceChanged: EventEmitter<any> = new EventEmitter();

  @Input() redirectionUrl = '/annonces/list';
  @Input() class = '';
  @Input() categories = [];


  constructor(
    public router: Router,
    private categoryServ: CategoryService,
    private localStorageServ: LocalStorageService
  ) { }

  ngOnInit(): void {

    this.localStorageServ.watchStorage().subscribe((data: string) => {
      this.address = this.localStorageServ.getItem('selectedRegion') ? this.localStorageServ.getItem('selectedRegion') : '';
    });
    this.getCategories();
  }



  setPlace(event) {
    this.place = event;
  }

  searchAdv() {

    this.router.navigate(['/annonces/list'],
      {
        queryParams:
        {
          'category': this.selectedCategory?._id,
          'region': this.place?.searchType === 'region' ? this.place?.region : '',
          'city': this.place?.searchType === 'city' ? this.place?.city : '',
          'name': this.keyWords
        }
      });


  }

  setDefaultCategorie(category) {

    category !== 'all' ? this.selectedCategory = category : this.selectedCategory = '';
    if (this.selectedCategory && this.selectedCategory?.subCategories?.length === 0) {
      this.showCategories = false;
    }

  }


  getCategories() {
    this.categoryServ.getAll().then(
      (resp: any) => {
        if (resp.status) {
          this.categories = resp?.data?.data?.categories;
        }
      }
    );
  }


  getActiveSubCategories(subCategories) {
    return subCategories.filter(x => x.isActive === true);
  }
}
