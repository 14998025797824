import { Component, NgZone, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';

@Component({
  selector: 'address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {

  @Output() PlaceChanged: EventEmitter<any> = new EventEmitter();
  @Input() address: any = '';
  @Input() type: any = 'search';
  @Input() class: any = 'addres-autocomplete-search';



  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private localStorageServ: LocalStorageService,
  ) { }


  location: any = [];

  SearchType = 'region';

  ngOnInit(): void {

    this.searchAddress()

    this.localStorageServ.watchStorage().subscribe((data: string) => {

      this.searchAddress()

    });

  }



  searchAddress() {


    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        <HTMLInputElement>document.getElementById('autocomplete-address'),
        {
          types: this.type !== 'userForm' ? ['(regions)'] : ['address'], // ['(address)'],
          // componentRestrictions: {
          //   country: ['fr'],
          //   country: ['fr', 'de', 'it', 'tn'],
          // },
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          if (
            autocomplete['gm_accessors_'] &&
            autocomplete['gm_accessors_']['place'] &&
            autocomplete['gm_accessors_']['place']['Xc'] &&
            autocomplete['gm_accessors_']['place']['Xc']['formattedPrediction']
          ) {
            this.address =
              autocomplete['gm_accessors_']['place']['Xc'][
              'formattedPrediction'
              ];
          }

          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place) {

            let data = {
              address: '',
              location: {},
              postal_code: '',
              city: '',
              country: '',
              region: '',
              street: '',
              searchType: '',
            };
           
            place.address_components.forEach(element => {

              if (element.types.includes('country')) {
                data.country = element.long_name;
              }
              if (element.types.includes('route')) {
                data.street = element.long_name;
              }

              if (element.types.includes('locality')) {
                data.city = element.long_name;
              }
              if (element.types.includes('administrative_area_level_1')) {
                data.region = element.long_name;
              }
              if (element.types.includes('postal_code')) {
                data.postal_code = element.long_name;
              }
            });


            this.location['latitude'] = place.geometry.location.lat();
            this.location['longitude'] = place.geometry.location.lng();
            this.address = place.formatted_address;
            data.location = this.location;
            data.address = this.address;
            data.searchType = data.city !== '' ? 'city' : 'region';
            this.PlaceChanged.emit(data);

          }
        });
      });
    });
  }
}
