import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit {


  register = false;
  @Input() type: any = 'signin';
  @Input() verifyAccount = false;
  @Input() email = '';

  message = '';
  success = false;


  constructor(
    private modalService: NgbModal,
    private sociAuthService: SocialAuthService,
    private localStorageServ: LocalStorageService,
    public translate: TranslateService,
    private authServ: AuthService,
    private toastr: ToastrService,
    private userServ: UserService,
  ) { }


  ngOnInit(): void {

  }



  close() {
    this.modalService.dismissAll();
  }


  signInWithGoogle(): void {
    this.sociAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (resp: any) => {
        if (resp) {
          this.authServ.signInGoogle({ token: resp?.idToken }).then(
            (response: any) => {

              if (response.status === true) {
                this.successLogin(response.data);
                this.success = true;
                this.message = 'Vous êtes maintenant connecté';
              } else {
                this.message = response.error?.error.message;
                this.success = false;
                setTimeout(() => {
                  this.message = '';
                }, 5000);
              }
            },
            error => {
              this.message = this.translate.instant('TOASTR.tryAgain');
              this.success = false;
              setTimeout(() => {
                this.message = '';
              }, 5000);
            }
          );
        }
      },
      (error: any) => {
        this.message = this.translate.instant('TOASTR.tryAgain');
        this.success = false;
        setTimeout(() => {
          this.message = '';
        }, 5000);
      }
    );
  }

  signInWithFB(): void {
    this.sociAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (resp: any) => {

        if (resp) {
          this.authServ.signInFacebook({ token: resp?.authToken }).then(
            (response: any) => {

              if (response.status === true) {
                this.successLogin(response.data);
                this.success = true;
                this.message = 'Vous êtes maintenant connecté';
              } else {
                this.message = response.error?.error.message;
                this.success = false;
                setTimeout(() => {
                  this.message = '';
                }, 5000);
              }
            },
            (error) => {
              this.message = this.translate.instant('TOASTR.tryAgain');
              this.success = false;
              setTimeout(() => {
                this.message = '';
              }, 5000);
            }
          );
        }
      },
      (error: any) => {
        this.message = this.translate.instant('TOASTR.tryAgain');
        this.success = false;
        setTimeout(() => {
          this.message = '';
        }, 5000);
      }
    );
  }

  signOut(): void {
    this.sociAuthService.signOut();
  }


  successLogin(data) {

    const user = data.user;
    const token = data.token;
    const tokenExpiresAt = data.tokenExpiresAt || new Date();

    console.log(data.tokenExpiresAt);
    
    this.localStorageServ.setItem('user_email', user.email);
    this.localStorageServ.setItem('user_role', user.role);
    this.localStorageServ.setItem('user_token', token);
    this.localStorageServ.setItem('user_token_end_time', tokenExpiresAt);

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  sendNewEmail() {
    this.userServ.sendVerification({ email: this.email }).then(
      (resp: any) => {
        if (resp.status) {
          this.toastr.success('Un email est envoyé avec succès', '', {
            timeOut: 1500,
          });
          this.modalService.dismissAll();
        } else {
          this.toastr.error('Une erreur est produite, veuillez réessayer plus tard', '', {
            timeOut: 1500,
          });
        }
      }
    )
  }

}
