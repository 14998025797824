<div class="col-md-12">
    <div *ngIf="message !==''" class="msg w-100 alert-danger" >
        <p class="m-0"> {{message}} </p>
    </div>
    
</div>

<igx-calendar 
#calendar 
selection="range" 
[weekStart]="1" 
[disabledDates]="disabledDate" 
[locale]="'FR'"
[showWeekNumbers]="false"
[formatOptions]="formatOptions"
[formatViews]="formatViews"
[monthsViewNumber]="1"
(onSelection)="selectPTOdays($event)"
>
</igx-calendar>
