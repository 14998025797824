<div class="row m-0">
  <div class="col-lg-12">
    <div class="singin">

      
        <h1> {{ 'HOME.signInHeading' | translate }} </h1>
        <p class="signup"> {{ 'HOME.newUser'| translate }} <a href="javascript:void(0)" (click)="changeType('register')">
            {{ 'HOME.SingupLink'| translate }} </a> </p>
  
     




        <div class="p-0">
          <div *ngIf="message !=='' ">
            <p class="aler alert-danger p-1 small">{{message}} </p>
          </div>
          <div class="login-sec">
            <div class="login-box">
              <form [formGroup]="loginForm" class="form-custom">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mb-1">
                      <label class="text-light-grey fs-14">{{ 'CLIENT.email' | translate }}</label>
                      <input type="email" name="#" class="form-control form-control-submit" placeholder="Email I'd"
                        required formControlName="email">
                    </div>
                    <div class="form-group mb-1">
                      <label class="text-light-grey fs-14">{{ 'CLIENT.password' | translate }}</label>
                      <input [type]="hide ? 'password' : 'text'" id="password-field" name="#"
                        class="form-control form-control-submit" value="password" placeholder="Password" required
                        formControlName="password">
                      <div data-name="#password-field" [class.fa-eye-slash]="hide === false"
                        [class.fa-eye]="hide === true" (click)="hide = !hide"
                        class="fa fa-fw field-icon toggle-password"></div>
                    </div>
                    <div class="form-group  mb-1 checkbox-reset">
                      <a class="reset-password" href="javascript:void(0)"
                        (click)="changeType('resetPassword')">{{ 'HOME.forgetPassword' | translate }}</a>
                    </div>


                    <!-- <div class="d-flex align-items-center justify-content-start  mt-1 mb-2">
                      <re-captcha class="mt-1 mb-1" (resolved)="resolved($event)" [siteKey]="captchaKey">
                      </re-captcha>
                    </div> -->

                    <div class="form-group text-center">

                      <button [disabled]="!loginForm.valid"   (click)="signin()" 
                      type="submit" class="btn-second btn-submit">
                        {{ 'HOME.signIn' | translate }}</button>

                    </div>



                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
     


    </div>

  </div>


</div>
