import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypesService {


  url = environment.Api_Url;

  constructor(private http: HttpClient) { }




  getAll(offset = 0, limit = 100) {
    return new Promise(resolve => {
      this.http.get(this.url + 'activity-types/' + offset + '/' + limit, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });

  }

}
