import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { AdvertsService } from 'src/app/services/adverts.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-svg-map',
  templateUrl: './svg-map.component.html',
  styleUrls: ['./svg-map.component.scss']
})
export class SvgMapComponent implements OnInit {


  selectedRegion = '';
  selectedId = 0;

  regions = [
    'Corse',
    'Centre',
    'Île-de-France',
    'Picardie',
    'Bourgogne',
    'Limousin',
    'Champagne-Ardenne',
    'Alsace',
    'Poitou-Charentes',
    'Auvergne-Rhône-Alpes',
    'Auvergne',
    'Franche-Comté',
    'Pays de la Loire',
    'Aquitaine',
    'Midi-Pyrénées',
    'Provence-Alpes-Côte d\'Azur',
    'Languedoc-Roussillon',
    'Basse-Normandie',
    'Haute-Normandie',
    'Nord-Pas-de-Calais',
    'Bretagne',
    'Lorraine',

  ];

  constructor(
    private localStorageServ: LocalStorageService,
    private activeroute: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {

    this.selectedRegion = this.localStorageServ.getItem('selectedRegion') ? this.localStorageServ.getItem('selectedRegion') : '';

    if (this.selectedRegion !== '') {

      this.selectedId = this.regions.indexOf(this.selectedRegion);

    }
  }

  setRegion(id) {
    this.selectedRegion = this.regions[id];
    this.localStorageServ.setItem('selectedRegion', this.selectedRegion);
    this.selectedId = id;
    this.router.navigate(['/annonces/list'], { queryParams: { 'region': this.selectedRegion } });
  }

}
