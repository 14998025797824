<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close(false)">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body payment-modal">

    <div class="row m-0 w-100 align-items-center justify-content-center" *ngIf="loading">
      <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate"> </mat-progress-spinner>
    </div>

    <div class="w-100" *ngIf="webviewUrl !== '' && !loading ">
       
      <div class="w-100 price-detail">
        <div class="col-md-12">
          <p class="info mt-3">Veuillez confirmer votre paiement, puis fermer ce popup</p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-6">
          <button class="btn btn-outline-success w-100 mt-2" (click)="close(false)"> 
            Annuler
          </button>
        </div>
        <div class="col-sm-6">
          <button class="btn btn-red w-100 mt-2" (click)="close(true)"> 
            Valider
          </button>
        </div>
      </div>


    </div>

</div>  