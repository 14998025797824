import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SigninGuard implements CanActivate {
  isSignedIn: boolean;

  constructor(
    private authServ: AuthService,
    private localStrgServ: LocalStorageService,
    private toastr: ToastrService,
    private router: Router
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const connectedUser = this.authServ.isSignedIn();
    const tokenEndDate = this.localStrgServ.getItem('user_token_end_time') || '';
    const dateNow = Math.floor(Date.now() / 1000);

    if (connectedUser && (dateNow < tokenEndDate)) {
      return true;
    }

    this.toastr.error('Votre session est terminé, veuillez vous connecter', '', {
      timeOut: 1500,
    });

    setTimeout(() => {
      this.router.navigate(['/']);
      this.localStrgServ.clearStorage();
    }, 2500);
    return false;
  }


}
