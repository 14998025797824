import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verification-email',
  templateUrl: './verification-email.component.html',
  styleUrls: ['./verification-email.component.scss']
})
export class VerificationEmailComponent implements OnInit {

  loadingSbmit = false;
  constructor(
    private activeroute: ActivatedRoute,
    private route: Router,
    private userServ: UserService,
    private toastr: ToastrService,

  ) {
    this.loadingSbmit = true;
    this.activeroute.queryParams.forEach(queryParams => {
      if (queryParams['token'] && queryParams['token'] !== "") {

        this.userServ.verifyEmail({token:queryParams['token']}).then(
          (resp: any) => {
            if (resp.status) {

              this.toastr.success('Votre email est verifié avec succée', '', {
                timeOut: 1500,
              });
              this.loadingSbmit = false;
              this.route.navigate(['/'])
            } else {
              this.toastr.error('Veuillez verifier vos informations et réessayez plus tard', '', {
                timeOut: 1500,
              });
              this.loadingSbmit = false;
              this.route.navigate(['/'])
            }
          }
        )

      } else {
        this.route.navigate(['/'])
      }

    });
  }

  ngOnInit(): void {
  }

}
