<div class="slider-form  {{class}}">

  <div class="row m-0 w-100">

    <div class="col-md-3">

      <div class="form-group">

        <div (click)="showCategories = !showCategories" class="w-100">
          <button *ngIf="!selectedCategory" class="btn btn-slider w-100">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-list">
                <line x1="8" y1="6" x2="21" y2="6" />
                <line x1="8" y1="12" x2="21" y2="12" />
                <line x1="8" y1="18" x2="21" y2="18" />
                <line x1="3" y1="6" x2="3.01" y2="6" />
                <line x1="3" y1="12" x2="3.01" y2="12" />
                <line x1="3" y1="18" x2="3.01" y2="18" /></svg>
            </span>
            {{ 'BUTTONS.categories' | translate }}
          </button>

          <button *ngIf="selectedCategory" class="btn btn-slider w-100">
            <span *ngIf="selectedCategory.icon" class="icon category-icon" [style.background-image]="'url('+ url + selectedCategory.icon?.path + ')'">
            </span>
            {{ selectedCategory?.name }}
          </button>

        </div>

      </div>


    </div>

    <div [class.col-md-3]=" class==='list-annonces'" [class.col-md-4]=" class !=='list-annonces'">
      <div class="form-group">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-search">
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
        </span>
        <input type="text" [(ngModel)]="keyWords" class="search form-control"
          placeholder="{{ 'HOME.search' | translate }}">
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-map-pin">
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
            <circle cx="12" cy="10" r="3" /></svg>
        </span>
        <address-input [address]="address" (PlaceChanged)="setPlace($event)"></address-input>

      </div>
    </div>

    <div [class.col-md-3]=" class==='list-annonces'" [class.col-md-2]=" class !=='list-annonces'">
      <button (click)="searchAdv()" class="btn btn-search">

        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-search">
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
        </span>

        {{ 'HOME.search' | translate }}
        <!-- <span class="result-number"> ( 159 563 788 Résultats ) </span> -->
      </button>
    </div>

    <div *ngIf="showCategories" class="category-list" [class.extended]="selectedCategory && selectedCategory.subCategories && selectedCategory.subCategories.length > 0">

      <div class="categories-content">

        <div (click)="setDefaultCategorie('all');showCategories = false" class="all category">
          <span class="item-category-content">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-list">
                <line x1="8" y1="6" x2="21" y2="6" />
                <line x1="8" y1="12" x2="21" y2="12" />
                <line x1="8" y1="18" x2="21" y2="18" />
                <line x1="3" y1="6" x2="3.01" y2="6" />
                <line x1="3" y1="12" x2="3.01" y2="12" />
                <line x1="3" y1="18" x2="3.01" y2="18" /></svg>
            </span>

            {{ 'BUTTONS.allcategories' | translate }}
          </span>
        </div>
        <div [class.active-category]="selectedCategory && selectedCategory._id === category._id"
          (click)="setDefaultCategorie(category)" class="category" *ngFor="let category of categories">
          <span class="item-category-content" [class.has-sub-categories]="category.subCategories.length > 0">
            <span class="icon category-icon" [style.background-image]="'url('+ url + category.icon?.path + ')'"> </span>
            {{category.name}}
          </span>



        </div>
        <div class="category">
          <span class="item-category-content">
            <a href="https://gosportmontagne.com/partner/lokatout" target="_blank">
              <span class="icon category-icon partner" [style.background-image]="'url(/assets/img/sky-category.jpeg)'"> </span>
              Ski
            </a>
          </span>
        </div>

      </div>

      <div *ngIf="selectedCategory && selectedCategory.subCategories && selectedCategory.subCategories.length > 0"
        class="sub-categories-content">
        <div (click)="setDefaultCategorie(subcategory);showCategories = false;"
          *ngFor="let subcategory of getActiveSubCategories(selectedCategory.subCategories)" class="sub-category-item">
          <span class="item-sub-content">
            <span *ngIf="subcategory.icon" class="icon category-icon" [style.background-image]="'url('+ url + subcategory.icon?.path + ')'">
            </span>
            {{subcategory.name}}
          </span>
        </div>
      </div>

    </div>
  </div>

</div>
