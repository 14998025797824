import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  message = '';
  captchaResponse = '';
  hide = true;
  isAuthenticated: boolean;

  signIn: any;
  captchaKey = environment.recaptchaSiteKey;


  @Output() ChangeType: EventEmitter<any> = new EventEmitter();


  constructor(
    private authServ: AuthService,
    private localStorageServ: LocalStorageService,
    private modalService: NgbModal,
    public translate: TranslateService,

  ) {

  }

  async ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', [Validators.required]),
    });


  }

  signin() {

    this.authServ.signin(this.loginForm.value).then(
      (resp: any) => {
        console.log(resp);

        if (resp.status === true) {

          const user = resp.data.user;
          const token = resp.data.token;
          const tokenExpiresAt = resp.data.tokenExpiresAt;
          this.localStorageServ.setItem('user_email', user.email);
          this.localStorageServ.setItem('user_verified', user.emailVerified);
          this.localStorageServ.setItem('user_role', user.role);
          this.localStorageServ.setItem('user_token', token);
          this.localStorageServ.setItem('user_token_end_time', tokenExpiresAt);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.message = resp.error?.error.message;
          setTimeout(() => {
            this.message = '';
          }, 5000);
        }
      },
      error => {
        this.message = this.translate.instant('TOASTR.tryAgain');
        setTimeout(() => {
          this.message = '';
        }, 5000);
      }
    );
  }
  close() {
    this.modalService.dismissAll();
  }


  resolved(captchaResponse: string) {

    this.captchaResponse = captchaResponse;
  }

  changeType(type: string) {
    this.ChangeType.emit(type)
  }


  // format_time(s) {
  //   const dtFormat = new Intl.DateTimeFormat('en-GB', { timeZone: 'UTC' });

  //   return dtFormat.format(new Date(s * 1e3));
  // }


}