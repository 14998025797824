<agm-map *ngIf="!is_List" #AgmMap [latitude]="lalt? lalt  : defaultlatitude"
  [longitude]="long? long :  defaultlongitude" [zoom]="zoom">



  <agm-circle 
  [latitude]="lalt? lalt  : defaultlatitude" 
  [longitude]="long? long :  defaultlongitude" 
  [circleDraggable]="false"
  [editable]="false"
  [fillColor]="'red'"
  [radius]="1000"
  >
</agm-circle>

  <!-- <agm-marker *ngIf="!showDirection" [latitude]="lalt? lalt  : defaultlatitude"
    [longitude]="long? long :  defaultlongitude">

    <agm-info-window class="map_info" [disableAutoPan]="true" address="addressList.address">

      <div class="content">

        <p class="m-0">
          <a rel="nofollow" target="_blank"
            href="https://www.google.fr/maps/dir//{{lalt}},{{long}}/@{{lalt}},{{long}},16z">
            <i class="fas fa-map-marker-alt"></i>
            {{address}}
          </a>
        </p>

      </div>



    </agm-info-window>

  </agm-marker> -->
  <!-- <agm-marker *ngIf="!showDirection && lalt2" [latitude]="lalt2? lalt2  : defaultlatitude"
    [longitude]="long2? long2 :  defaultlongitude">
  </agm-marker>

  <agm-direction *ngIf="showDirection" [origin]="origin" [destination]="destination" [optimizeWaypoints]="true"
    [travelMode]="'BICYCLING'">
  </agm-direction> -->

</agm-map>


<div *ngIf="is_List" class="list">

  <agm-map #AgmMap 
    [latitude]="lalt? lalt  : defaultlatitude" 
    [longitude]="long? long :  defaultlongitude"
    (zoomChange)="zoomChange($event)" 
    (centerChange)="centerChange($event)" 
    [usePanning]="true" 
    [maxZoom]="16"
    [minZoom]="11" 
    [zoom]="zoom">

    <agm-marker *ngFor="let item of addressList"
      [latitude]="item.location.coordinates[0]? item.location.coordinates[0] : defaultlatitude"
      [longitude]="item.location.coordinates[1]? item.location.coordinates[1] :  defaultlongitude">
      [iconUrl]="'/assets/img/svg/location-pin.svg'"

      <agm-info-window class="map_info" [disableAutoPan]="true" address="addressList.address">

        <a [routerLink]="'/restaurants/'+item.restaurant_slug">
          <img [src]="item.cover ? item.cover : 'assets/img/svg/Logo.svg'" alt="" />
        </a>
        <div class="content">
          <p class="name">
            <a [routerLink]="'/restaurants/'+item.restaurant_slug">
              {{item.restaurant}}
            </a>
          </p>
          <p class="m-0"><i class="fas fa-map-marker-alt"></i>
            <a rel="nofollow" target="_blank"
              href="https://www.google.fr/maps/dir//{{item.location.coordinates[0]}},{{item.location.coordinates[1]}}/@{{item.location.coordinates[0]}},{{item.location.coordinates[1]}},16z">
              {{item.address}}
            </a>
          </p>
          <p class="m-0"><i class="fas fa-phone-alt"></i> <a href="tel:{{item.phone}}" rel="nofollow" target="_blank">
              {{item.phone}} </a></p>
        </div>



      </agm-info-window>

    </agm-marker>

  </agm-map>

</div>
