<div class="row m-0">
  <div class="w-100">
    <div class="singin">

      <div class="col-md-12">
        <h1> {{ 'HOME.signUpHeading' | translate }} </h1>
        <p class="signup"> {{ 'HOME.alreadyUser'| translate }} <a href="javascript:void(0)"
            (click)="changeType('signin')">
            {{ 'HOME.signIn'| translate }} </a> </p>
  
        <div *ngIf="message !=='' ">
          <p [class.alert-danger]="!success" [class.alert-success]="success" class="aler  p-1 small">{{message}} </p>
        </div>
  
      </div>

      <div class="register">
        <div class="login-box">
          <form [formGroup]="registerForm" class="form-custom">
            <div class="row m-0 w-100">

              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.lastName' | translate }}</label>
                  <input type="text" name="#" class="form-control form-control-submit" formControlName="lastName"
                    placeholder="Nom" required>

                  <div *ngIf="submited && getControl('lastName').errors?.required" class="alert-danger">
                    <div *ngIf="getControl('lastName').errors.required">{{ 'CLIENT.lastName' | translate }}
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>


                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.firstName' | translate }}</label>
                  <input type="text" name="#" class="form-control form-control-submit" formControlName="firstName"
                    placeholder="Prénom" required>

                  <div *ngIf="submited && getControl('firstName').errors?.required" class="alert-danger">
                    <div *ngIf="getControl('firstName').errors.required">{{ 'CLIENT.firstName' | translate }}
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>


                </div>
              </div>

            </div>

            <div class="row m-0 w-100">
              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.email' | translate }}</label>
                  <input type="email" name="#" class="form-control form-control-submit" formControlName="email"
                    placeholder="Email" required>

                  <div *ngIf="submited && getControl('email').errors?.required" class="alert-danger">
                    <div *ngIf="getControl('email').errors.required">{{ 'CLIENT.email' | translate }}
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>


                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.phone' | translate }}</label>
                  <input type="text" class="form-control form-control-submit" formControlName="phone"
                    placeholder="{{ 'CLIENT.phone' | translate }}" required>

                  <div *ngIf="submited && getControl('phone').errors" class="alert-danger">
                    <div *ngIf="getControl('phone').errors.required">{{ 'CLIENT.phone' | translate }}
                      {{ 'CLIENT.required' | translate }}.</div>
                    <div *ngIf="getControl('phone').errors.minlength"> le Tél doit contenir au minimum 10
                      caractères.</div>
                    <div *ngIf="getControl('phone').errors.maxlength"> le Tél doit contenir au maximum 10
                      caractères.</div>
                  </div>


                </div>
              </div>
            </div>


            <div class="row m-0 w-100">
              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.password' | translate }}</label>
                  <input [type]="hide ? 'password' : 'text'"
                    class="form-control form-control-submit" value="password" placeholder="Password" required
                    formControlName="password">
                  <div data-name="#password-field" [class.fa-eye-slash]="hide === false" [class.fa-eye]="hide === true"
                    (click)="hide = !hide" class="fa fa-fw field-icon toggle-password">
                  </div>


                  <div *ngIf="submited && getControl('password').errors" class="alert-danger">

                    <div *ngIf="getControl('password').errors.required">{{ 'CLIENT.password' | translate }}
                      {{ 'CLIENT.required' | translate }}.</div>
                    <div *ngIf="getControl('password').errors.minlength"> le mot de passe doit contenir au minimum 8
                      caractères. </div>
                  </div>


                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14">{{ 'CLIENT.confirmPassword' | translate }}</label>

                  <input [type]="hideConf ? 'password' : 'text'"
                    class="form-control form-control-submit" value="password" placeholder="Password" required
                    formControlName="confirmPassword">
                  <div data-name="#password-field" [class.fa-eye-slash]="hideConf === false"
                    [class.fa-eye]="hideConf === true" (click)="hideConf = !hideConf"
                    class="fa fa-fw field-icon toggle-password"></div>

                  <div *ngIf="submited && getControl('confirmPassword').errors" class="alert-danger">
                    <div *ngIf="getControl('confirmPassword').errors.required">
                      {{ 'CLIENT.confirmPassword' | translate }} {{ 'CLIENT.required' | translate }}.</div>
                    <div *ngIf="getControl('confirmPassword').errors.minlength"> le mot de passe doit contenir au
                      minimum 8 caractères.</div>
                  </div>

                  <div *ngIf="submited && (registerForm.value.password !== registerForm.value.confirmPassword ) "
                    class="alert-danger">
                    <div> les mots de pass ne sont pas identiques.</div>
                  </div>


                </div>
              </div>
            </div>

            <div class="row w-100 m-0 align-items-start justify-content-start">
              <div class="col-md-12">
                <label> Date de naissance </label>
  
  

                <div class="d-flex">
                  <input matInput class="form-control" [max]="maxyearDate" formControlName="birthday"
                    [matDatepicker]="picker2">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
  
                <div *ngIf="submited && (getControl('birthday').errors?.required)" class="alert-danger">
                  <div *ngIf="getControl('birthday').errors.required"> Date de naissance requis.</div>
                </div>
  
  
              </div>
  
            </div>


            <div class="row w-100 m-0 align-items-start justify-content-start">
              <div class="col-md-12">

                <div class="form-group">
                  <label class="text-grey-white"> Vous êtes </label> <br>
                  <mat-radio-group (change)="ChangeUserType()" formControlName="userType" aria-label="Select an option">
                    <mat-radio-button class="mr-2" value="NP"> {{ 'CLIENT.lp' | translate }} </mat-radio-button>
                    <mat-radio-button value="LP"> {{ 'CLIENT.np' | translate }} </mat-radio-button>
                  </mat-radio-group>
                </div>


              </div>

            </div>


            <div *ngIf="registerForm.value.userType === 'LP'"
              class="row w-100 m-0 align-items-start justify-content-start">

              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14"> Nom du société </label>
                  <input type="text" class="form-control form-control-submit" formControlName="companyName"
                    placeholder="Nom du société" required>

                  <div *ngIf="submited && getControl('companyName').errors" class="alert-danger">
                    <div *ngIf="getControl('companyName').errors.required"> Nom du société
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>
                </div>
              </div>


              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14"> Tél </label>
                  <input type="text" class="form-control form-control-submit" formControlName="companyPhone"
                    placeholder="Tél du société" required>

                    <div *ngIf="submited && getControl('companyPhone').errors" class="alert-danger">
                      <div *ngIf="getControl('companyPhone').errors.required"> le Tél
                        {{ 'CLIENT.required' | translate }}.</div>
                      <div *ngIf="getControl('companyPhone').errors.minlength"> le Tél doit contenir au minimum 10
                        caractères.</div>
                      <div *ngIf="getControl('companyPhone').errors.maxlength"> le Tél doit contenir au maximum 10
                        caractères.</div>
                    </div>

                </div>
              </div>



              <div class="col-md-6">
                <div class="form-group mb-1">
                  <label class="text-light-grey fs-14"> Siret </label>
                  <input type="text" class="form-control form-control-submit" formControlName="companySiret"
                    placeholder="Siret" required>

                  <div *ngIf="submited && getControl('companySiret').errors" class="alert-danger">
                    <div *ngIf="getControl('companySiret').errors.required"> Siret
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>
                </div>
              </div>


              <div class="col-md-6">
                <div class="form-group">
                  <label class="text-grey-white"> Type d'activité </label>
                  <select formControlName="companyActivityType" class="form-control select-category">
                      <option value="null" selected> Choisissez une activité  </option>
                      <option *ngFor="let activity of activityTypes"  [value]="activity._id"> {{activity.name}} </option>
                  </select>

                  <div *ngIf="submited && getControl('companyActivityType').errors" class="alert-danger">
                    <div *ngIf="getControl('companyActivityType').errors.required"> Type d'activité
                      {{ 'CLIENT.required' | translate }}.</div>
                  </div>


              </div>
              </div>

            </div>


            <div class="col-12">

              <!-- <span class="text-light-black fs-12 terms">{{ 'CLIENT.accept1' | translate }}
                <a href="javascript:void(0)"> {{ 'CLIENT.accept2' | translate }} </a>
                {{ 'CLIENT.accept3' | translate }}
                <a href="javascript:void(0)">{{ 'CLIENT.accept4' | translate }}</a>
              </span> -->

              <div class="form-group w-100 mb-3 checkbox-reset mt-2">
                <label class="custom-checkbox mb-0">
                  <input formControlName="acceptUserCondition" type="checkbox"> <span class="checkmark">
                  </span> Je déclare avoir pris connaissance des 
                   <a routerLink="/page/condition-general-utilisation" >Conditions d’utilisation des services et je les accepte </a> 
                   </label>
              </div>


              <!-- <div class="d-flex align-items-center justify-content-start mt-1 mb-1 ">
                  <re-captcha class="mt-1 mb-1" (resolved)="resolved($event)" [siteKey]="captchaKey"></re-captcha>
                </div>
                 -->
              <!-- <div class="form-group mb-1">
                  <button [disabled]="captchaResponse ===''" type="submit" class="btn-second btn-submit full-width"
                    (click)="createAccount()">{{ 'BUTTONS.createYourAccount' | translate }}</button>
                </div>                 -->

              <div class="form-group mb-1">
                <button [disabled]="!registerForm.value.acceptUserCondition" type="submit" class="btn-second btn-submit full-width"
                  (click)="createAccount()">{{ 'BUTTONS.createYourAccount' | translate }}</button>
              </div>

            </div>

          </form>
        </div>
      </div>

    </div>
  </div>
