import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  @Output() PasswordChanged: EventEmitter<any> = new EventEmitter();
  @Output() ChangeType: EventEmitter<any> = new EventEmitter();


  resetForm: FormGroup;
  newPasswordForm: FormGroup;
  message = '';
  role = '';
  captchaResponse = '';
  hideConf = true;
  hide = true;
  success = false;
  proceed = false;

  submited = false;

  captchaKey = environment.recaptchaSiteKey;
  constructor(
    private authServ: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {


    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    })

    this.newPasswordForm = new FormGroup({
      token: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

  }


  resolved(captchaResponse: string) {

    this.captchaResponse = captchaResponse;
  }


  SendEmail() {
    if (this.resetForm.valid) {
      this.authServ.resetPassword(this.resetForm.value).then(
        (resp: any) => {

          if (resp.status) {
            this.message = 'Un e-mail contenant un code de réinitialisation a été envoyé avec succès à ' + this.resetForm.value.email;
            this.success = true;

            setTimeout(() => {
              this.message = '';
              this.captchaResponse = '';
              this.proceed = true;
            }, 3000);

          } else {
            this.success = false;
            this.message = 'aucun compte trouvé avec cet e-mail :' + this.resetForm.value.email;
            this.captchaResponse = '';
            setTimeout(() => {
              this.message = '';
            }, 3000);
          }
        }
      )
    }

  }


  resetPassword() {
    this.submited = true;
    if (this.newPasswordForm.valid) {
      const data = {
        password: this.newPasswordForm.value.password,
        code: this.newPasswordForm.value.token
      };
      this.authServ.changePassword(data).then(
        (resp: any) => {

          if (resp.status) {
            this.message = 'Mot de passe mis à jour avec succès, vous pouvez vous connecter maintenant';
            this.success = true;
            setTimeout(() => {
              this.message = '';
              this.captchaResponse = '';
              this.ChangeType.emit('signin')
            }, 2000);

          } else {
            this.success = false;
            this.message = 'Une erreur est produite, veuillez verifier le code envoyé';
            this.captchaResponse = '';
            setTimeout(() => {
              this.message = '';
            }, 3000);
          }

        }

      )
    }
  }


  changeType(type: string) {
    this.ChangeType.emit(type)
  }


  getControl(control) {
    return this.newPasswordForm.get(control);
  }
}
