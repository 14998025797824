import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'lokatout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  categories: any = [];

  constructor(
    private categoryServ: CategoryService,
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }




  getCategories() {
    this.categoryServ.getAll().then(
      (resp: any) => {
        if (resp.status) {
          this.categories = resp?.data?.data?.categories || [];
        }
      }
    );
  }

}
