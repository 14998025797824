import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';

import { MatNativeDateModule } from '@angular/material/core';

import { ImageCropperModule } from 'ngx-image-cropper';
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SvgMapComponent } from './svg-map/svg-map.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NgxStripeModule } from 'ngx-stripe';
import * as enviDev from 'src/environments/environment';
import { CalendarComponent } from './calendar/calendar.component';

import { IgxCalendarModule, IgxDialogModule } from 'igniteui-angular';
import { VerificationEmailComponent } from '../shared/verification-email/verification-email.component';
import { StripeSecureAuthComponent } from './stripe-secure-auth/stripe-secure-auth.component';


@NgModule({
  declarations: [
    ChangePasswordComponent,
    SvgMapComponent,
    PaymentModalComponent,
    CalendarComponent,
    VerificationEmailComponent,
    StripeSecureAuthComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatCardModule,
    NgbModule,

    IgxCalendarModule,
    IgxDialogModule,

    NgxSpinnerModule,
    SlickCarouselModule,
    NgxStripeModule.forRoot(enviDev.environment.stripePkey),
    ImageCropperModule,
    FormsModule, ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    ChangePasswordComponent,
    TranslateModule,
    SvgMapComponent,
    PaymentModalComponent,
    StripeSecureAuthComponent,
    CalendarComponent],
  entryComponents: [
    PaymentModalComponent,
    StripeSecureAuthComponent,
    CalendarComponent
  ]
})
export class SharedModule { }
