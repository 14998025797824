import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from '../core/interceptor/http-error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthentificationComponent } from './authentification/authentification.component';
import { SigninComponent } from './signin/signin.component';
import { RegisterComponent } from './register/register.component';


import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';

import { AddressInputComponent } from './address-input/address-input.component';


import { RecaptchaModule } from "ng-recaptcha";
import { CategoryBoxComponent } from './category-box/category-box.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { SharedModule } from '../shared/shared.module';
import { CategoriesListComponent } from './categories-list/categories-list.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AdvBoxComponent } from './adv-box/adv-box.component';
import { FooterComponent } from './footer/footer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { AdvFormComponent } from './adv-form/adv-form.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { ViewMapComponent } from './view-map/view-map.component';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { environment } from 'src/environments/environment';
import { AdversListComponent } from './advers-list/advers-list.component';

@NgModule({
  declarations: [
    NavbarComponent,

    AuthentificationComponent,
    SigninComponent,
    RegisterComponent,
    AddressInputComponent,
    CategoryBoxComponent,
    HomeSliderComponent,
    CategoriesListComponent,
    AdvBoxComponent,
    FooterComponent,
    ResetPasswordComponent,
    SearchFormComponent,
    AdvFormComponent,
    ViewMapComponent,
    AdversListComponent,

  ],
  imports: [
    CommonModule,
    MatFormFieldModule,


    SharedModule,
    SlickCarouselModule,

    MatSelectModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatCheckboxModule,
    
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    RecaptchaModule,
    AgmDirectionModule,
    ImageCropperModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places']
    }),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: httpTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // })
  ],
  exports: [
    NavbarComponent,

    SigninComponent,
    RegisterComponent,
    AuthentificationComponent,
    AddressInputComponent,
    CategoryBoxComponent,
    HomeSliderComponent,
    CategoriesListComponent,
    AdversListComponent,
    AdvBoxComponent,
    FooterComponent,
    SearchFormComponent,
    AdvFormComponent,
    ViewMapComponent,
  ],
  entryComponents: [
    AuthentificationComponent,
    SigninComponent,
    RegisterComponent,
    CategoryBoxComponent,
    HomeSliderComponent,
    CategoriesListComponent,
    AdversListComponent,
    AdvBoxComponent,
    FooterComponent,
    SearchFormComponent,
    ViewMapComponent,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }
  ],
})
export class LayoutsModule { }
