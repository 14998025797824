<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">





  <div class="row align-items-center justify-content-center" *ngIf="!verifyAccount">

    <div *ngIf="type !== 'resetPassword'" class="col-lg-6">

      <div class="signup-heading">
        <h1 *ngIf="!verifyAccount"> {{ 'HOME.welcome' | translate }} </h1>
        <h1 *ngIf="verifyAccount"> Compte non verifié </h1>
      </div>

      <img src="/assets/img/singup.png" alt="">

    </div>

    <div class="col-lg-6">


      <div class="w-100" *ngIf="message !=='' ">
        <p [class.alert-danger]="!success" [class.alert-success]="success" class="aler  p-1 small">{{message}} </p>
      </div>

      <div *ngIf="type === 'signin'">
        <app-signin (ChangeType)="type = $event"></app-signin>
      </div>

      <div *ngIf="type === 'register'">
        <app-register (ChangeType)="type = $event"></app-register>
      </div>

      <div *ngIf="type === 'resetPassword'">
        <app-reset-password (ChangeType)="type = $event"></app-reset-password>
      </div>

      <div *ngIf="type !== 'resetPassword'">



        <div class="social-login row m-0">
          <div class="col-md-6">
            <a class="facebook-btn" (click)="signInWithFB()">
              <i class="fab fa-facebook-f"></i>
            </a>
          </div>
          <div class="col-md-6">
            <a class="google-btn" (click)="signInWithGoogle()">
              <i class="fab fa-google"></i>
            </a>
          </div>
        </div>


      </div>
    </div>

  </div>

  <div class="row align-items-center justify-content-center" *ngIf="verifyAccount">
    <div class="col-lg-12">
      <div class="signup-heading text-center">
        <div class="alert-warning user-email-verification p-2">
          <p class="m-0">
           Vous devez valider votre compte en cliquant sur le lien que vous avez reçu par email pour débloquer l'accès à
           votre espace utilisateur. </p>
           <p class="m-0">
             Si vous avez perdu votre email, <a (click)="sendNewEmail()">cliquez ici</a> pour recevoir un nouveau lien par email
           </p>
         </div>
      </div>
    </div>
  </div>

</div>
