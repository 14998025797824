<div class="footer">

  <div class="container">

    <div class="row">
      <div class="col-md-4  col-lg-3 col-sm-6" *ngFor="let category of categories">

        <div class="category w-100">
          <div class="category-header">
            <a href="/annonces/list?category={{category._id}}" class="text-light-black">
              {{category.name}} </a>
          </div>
          <!-- <div class="sub-category">
            <ul>
              <li *ngFor="let subCat of category.subCategories"> <a routerLink="/annonces/list" [queryParams]="{category:subCat._id}" class="text-light-black"> {{subCat.name}} </a>  </li>
            </ul>
          </div> -->
        </div>

      </div>

      <div class="col-md-4  col-lg-3 col-sm-6">


        <div class="category w-100">
          <div class="category-header">
            <a href="https://gosportmontagne.com/partner/lokatout" target="_blank" class="text-light-black">
              Ski </a>
          </div>
        </div>



      </div>
    </div>
  </div>

</div>

<div class="footer-after bg-blue">

  <div class="container">

    <div class="row">


      <div class="col-md-4  col-lg-4 col-sm-6 w-100">

        <div class="row">
          <div class="link-title  w-100">
            <h3> À PROPOS DU Lokatout </h3>
          </div>
          <div class="links  w-100">
            <a routerLink="/page/qui-somme-nous">Qui sommes-nous ?</a>
            <a routerLink="/page/nous-rejoindre">Nous contacter</a>
          </div>
        </div>


        <div class="row">
          <div class="link-title w-100">
            <h3> NOS APPLICATIONS </h3>
          </div>
          <div class="links  w-100">
            <a href="https://apps.apple.com/fr/app/lokatout/id1591235520" target="_blank"> Télécharger l’application iOS</a>
            <a href="https://play.google.com/store/apps/details?id=lokatout.com" target="_blank">Télécharger l’application Android</a>
          </div>
        </div>
        
      </div>


      <div class="col-md-4  col-lg-4 col-sm-6">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> INFORMATIONS LÉGALES </h3>
          </div>
          <div class="links  w-100">
            <a routerLink="/page/vie-privee-cookies"> Vie privée / cookies</a>
            <a routerLink="/page/condition-general-utilisation"> Conditions générales d’utilisation </a>
            <!-- <a routerLink="/page/condition-general-utilisation"> Conditions générales d’utilisation </a> -->
            <!-- <a routerLink="/page/condition-general-vente"> Conditions générales de vente</a> -->
            <a href="javascript:void(0)"> Conditions générales de vente</a>
            <a routerLink="/page/vous-droit-obligations"> Vos droits et obligations </a>
            <!-- <a href="javascript:void(0)"> Vos droits et obligations </a> -->
            <!-- <a href="javascript:void(0)"> Critères de classement</a> -->
            <!-- <a href="javascript:void(0)"> Décisions de justice </a> -->
          </div>
        </div>

      </div>



      <div class="col-md-4  col-lg-4 col-sm-6">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> NOS SOLUTIONS PROS </h3>
          </div>
          <div class="links  w-100">
            <!-- <a href="javascript:void(0)"> Publicité </a> -->
            <!-- <a routerLink="/page/professionnels-immobilier"> Professionnels de l’immobilier</a> -->
            <a href="javascript:void(0)"> Professionnels de l’immobilier</a>
            <!-- <a href="javascript:void(0)"> Vos recrutements </a> -->
            <a href="javascript:void(0)"> Professionnels de l’auto</a>
            <!-- <a routerLink="/page/professionnels-auto"> Professionnels de l’auto</a> -->
            <a href="javascript:void(0)"> Professionnels du tourisme </a>
            <!-- <a routerLink="/page/professionnels-tourisme"> Professionnels du tourisme </a> -->
            <!-- <a href="javascript:void(0)"> Autres solutions professionnelles </a> -->
            <!-- <a href="javascript:void(0)"> Annuaire des professionnels </a> -->
            <!-- <a href="javascript:void(0)"> Dépôt gratuit d’emploi pour les TPE </a> -->
          </div>
        </div>

      </div>


      <!-- <div class="col-md-4  col-lg-3 col-sm-6">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> DES QUESTIONS ?</h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)"> Aide </a>
            <a href="javascript:void(0)"> Le service de paiement sécurisé et la livraison </a>
            <a href="javascript:void(0)"> Le service de réservation de vacances en ligne pour les hôtes </a>
            <a href="javascript:void(0)"> Demandez conseil à la Communauté </a>
            <a href="javascript:void(0)"> Statut de nos services</a>
          </div>
        </div>

      </div> -->


    </div>

  </div>
</div>

<div class="footer-copy-right">
  <div class="col-md-12 text-center">
    <p class="m-0"> Copyright © 2021 Lokatout Tous droits réservés </p>
  </div>
</div>
