import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn;

  url = environment.Api_Url;
  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageServ: LocalStorageService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  /**
   * signin: Save user's data when cedentials data exists
   * @param credentialsData : user's data
   */
  signin(credentialsData: object) {

    return new Promise(resolve => {
      this.http.post(this.url + 'login', credentialsData, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res.data });
      }, (err) => {
        resolve({ status: false, error: err.error });
      });
    });
  }


  signInGoogle(token) {

    return new Promise(resolve => {
      this.http.post(this.url + 'login/google', token, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res.data });
      }, (err) => {
        resolve({ status: false, error: err.error });
      });
    });
  }


  signInFacebook(token) {

    return new Promise(resolve => {
      this.http.post(this.url + 'login/facebook', token, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res.data });
      }, (err) => {
        resolve({ status: false, error: err.error });
      });
    });
  }


  /**
   * 
   * @param credentialsData : user's form data
   */
  register(credentialsData: object) {
    return new Promise(resolve => {
      this.http.post(this.url + 'signup', credentialsData, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  managerRegister(credentialsData: object) {
    return new Promise(resolve => {
      this.http.post(this.url + 'users/manager/register', credentialsData, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  signout() {
    return new Promise(resolve => {
      this.http.get(this.url + 'logout', {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  getConnectedUser() {
    return new Promise(resolve => {
      this.http.get(this.url + 'users/profile', {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        this.toastr.error('Votre session est terminé, veuillez vous connecter', '', {
          timeOut: 1500,
        });
        setTimeout(() => {
          this.localStorageServ.clearStorage();
        }, 2000);


        resolve({ status: false, error: err });
      });
    });
  }

  isSignedIn(): boolean {
    return this.localStorageServ.getItem('user_email') ? true : false;
  }

  isVerified(): boolean {

    return this.localStorageServ.getItem('user_verified') ? true : false;
  }

  /**
   * Check if user belongs to auth allowedRoles list
   * @param allowedRoles: string[]
   */
  isAuthorized(allowedRoles: string[]): boolean {

    let authorized = false;

    if (!allowedRoles) {
      authorized = true;
    }
    const user = this.localStorageServ.getItem('user_role');

    // Case: no user found
    if (user === null) {
      authorized = false;
    }

    user.forEach(element => {
      if (allowedRoles.includes(element)) {
        authorized = true;
      }
    });

    // Return compare roles check
    return authorized;
  }

  /**
  * Get connectd user role
  */
  getUserRole() {
    const user = this.localStorageServ.getItem('user_role');
    return user ? user.role : null;
  }

  changePassword(newPasswordForm) {
    return new Promise(resolve => {
      this.http.post(this.url + 'reset-password', newPasswordForm, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  resetPassword(data) {
    return new Promise(resolve => {
      this.http.post(this.url + 'forgot-password', data, {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }
}
