import { Component, ElementRef, OnInit, NgZone, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LocalStorageService } from './services/localstorage/local-storage.service';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { DatePipe, DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        DatePipe,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    theme$: Observable<boolean>;

    showRobots = environment.showRobots;

    latitude: number;
    longitude: number;
    zoom: number;
    address: string;
    private geoCoder;
    config: any;
    currentPath: any;
    whiteLabelConfig: any;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        public datepipe: DatePipe,
        public translate: TranslateService,
        private titleService: Title,
        private metaTagService: Meta,
    ) {
    }

    ngOnInit() {
        this.recallJsFuntions();


        this.titleService.setTitle('Lokatout');

        // this.metaTagService.addTags([
        //     { name: 'robots', content: 'index, follow' },
        //     { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        // ]);

        this.metaTagService.addTags([
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1'
            },
            {
                name: 'robots',
                content: this.showRobots ? 'index, follow' : 'noindex, unfollow'
            }
        ]);

        console.log(this.showRobots);


    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('#preloaders').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $('#preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

}


