<div class="annonce-item mt-4 mb-4" *ngIf="annonce">

  <a href="/annonces/detail/{{annonce.slug ? annonce.slug : annonce._id }}"  
  [title]="annonce.title" 
  class="text-light-black">
    <div class="annonce-box">
      <div class="annonce-cover">
        <a href="/annonces/detail/{{annonce.slug ? annonce.slug : annonce._id}}">
          <div *ngIf="!annonce.cover?.path" [style.backgroundImage]="'url(assets/img/not-found.jpg)'"
            class="img-fluid full-width"></div>

          <div  *ngIf="annonce.cover?.path" 
          [style.backgroundImage]="'url('+ annonceMedia +'400/'+  annonce.cover.filename +')'"
          class="img-fluid full-width">

        </div>
        <img class="d-none" [src]="annonceMedia +'1/'+  annonce.cover.filename" [title]="annonce.title" [alt]="annonce.title" width="100%">
        </a>
      </div>
      <div class="annonce-caption">

        <div class="title-box">
          <span class="annonce-title"> {{ annonce.title.length > 25 ?  (annonce.title| slice:0:25) + ' ... ' : annonce.title }} </span>
        </div>

        <div class="address-box">
          <span class="annonce-address">{{annonce.address?.street}} </span>
        </div>


        <div class="annonce-footer-box row">
          <div class="col-md-12">
            <span class="annonce-price" *ngIf="annonce.prices">

              <!-- {{annonce.prices[0]?.total}} € / {{annonce.prices[0]?.period | translate }} -->

              {{getSpecificPrice()| number: '1.2-2' }} € / {{ getSpecificPeriod() | translate }}
              
            </span>
          </div>
          <!-- <div class="col-md-6 text-right">

            <span [class.danger]="annonce.status?.code === 2" [class.success]="annonce.status?.code === 1"
              class="adv-status">
              {{annonce.status?.description}}
            </span>


          </div> -->

        </div>


      </div>

    </div>

  </a>
</div>
