import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { SigninComponent } from 'src/app/layouts/signin/signin.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTypesService } from 'src/app/services/activity-types.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  message = '';
  role = '';
  captchaResponse = '';
  todayDate: any;
  maxyearDate: any;
  signIn: NgbModalRef;
  hideConf = true;
  hide = true;

  submited = false;
  success = false;

  activityTypes: any = [];

  captchaKey = environment.recaptchaSiteKey;

  @Output() ChangeType: EventEmitter<any> = new EventEmitter();

  constructor(
    private authServ: AuthService,
    private localStorageServ: LocalStorageService,
    private activityTypeServ: ActivityTypesService,
    public translate: TranslateService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),

      userType: new FormControl('NP', [Validators.required]),
      birthday: new FormControl('', [Validators.required]),
      acceptUserCondition: new FormControl(false, [Validators.required]),

      companyStreet: new FormControl(''),
      companyPostalCode: new FormControl(''),
      companyCountry: new FormControl(''),
      companyCity: new FormControl(''),
      companySiret: new FormControl(''),
      companyName: new FormControl(''),
      companyActivityType: new FormControl(''),
      companyPhone: new FormControl(''),
    });

    this.getActivityTypes();

    this.maxyearDate = new Date(new Date().getFullYear() - 18, 11, 31);
  }


  getActivityTypes() {

    this.activityTypeServ.getAll().then(
      (resp: any) => {
        if (resp.status) {
          this.activityTypes = resp.data.data.activityTypes || [];
        }
      });


  }


  createAccount() {

    this.submited = true;
    if (this.registerForm.valid && (this.registerForm.value.password === this.registerForm.value.confirmPassword)) {
      let data: any = {
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        firstName: this.registerForm.value.firstName,
        lastName: this.registerForm.value.lastName,
        phone: this.registerForm.value.phone,
        userType: this.registerForm.value.userType,
        birthdate: this.registerForm.value.birthday,
      };

      if (this.registerForm.value.userType === 'LP') {

        const company = {
          street: this.registerForm.value.companyStreet,
          postalCode: this.registerForm.value.companyPostalCode,
          city: this.registerForm.value.companyCity,
          country: this.registerForm.value.companyCountry,
          siret: this.registerForm.value.companySiret,
          name: this.registerForm.value.companyName,
          activityType: this.registerForm.value.companyActivityType,
          phone: this.registerForm.value.companyPhone,
        };

        data.company = company;

      }

      this.authServ.register(data).then(
        (resp: any) => {
          if (resp && resp.status === true) {

            this.message = this.translate.instant('CLIENT.accountCreated');
            this.success = true;
            setTimeout(() => {
              this.message = '';
              this.submited = false;
              this.ChangeType.emit('signin');
            }, 3000);

          } else {
            this.message = resp.error?.error?.error?.message;
            this.success = false;
            setTimeout(() => {
              this.message = '';
              this.submited = false;
            }, 3000);
          }
        },
        error => {
        }
      );

    } else {
      this.getFormValidationErrors(this.registerForm);
    }

  }

  close() {
    this.modalService.dismissAll();
  }


  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }


  changeType(type: string) {
    this.ChangeType.emit(type);
  }


  getControl(control) {
    return this.registerForm.get(control);
  }


  getFormValidationErrors(form) {
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }


  ChangeUserType() {

    if (this.registerForm.value.userType === 'LP') {
      this.registerForm.get('companySiret').setValidators([Validators.required]);
      this.registerForm.get('companyName').setValidators([Validators.required]);
      this.registerForm.get('companyPhone').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
      this.registerForm.get('companyActivityType').setValidators([Validators.required]);
    } else {

      this.registerForm.get('companySiret').clearValidators();
      this.registerForm.get('companySiret').updateValueAndValidity();


      this.registerForm.get('companyPhone').clearValidators();
      this.registerForm.get('companyPhone').updateValueAndValidity();


      this.registerForm.get('companyName').clearValidators();
      this.registerForm.get('companyName').updateValueAndValidity();

      this.registerForm.get('companyActivityType').clearValidators();
      this.registerForm.get('companyActivityType').updateValueAndValidity();
    }

  }

}
