<div class="row">
  <div class="col-lg-8">
    <div class="slider-description">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-7 text-center">
          <p class="first-element"> Lokatout </p>
          <p class="second-element">
            {{ 'HOME.sliderDescription' | translate }}
          </p>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-3">
        <div class="col-md-5">
          <a routerLink="/annonces/deposer-une-annonce" class="btn btn-add-post">
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-plus-square">
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <line x1="12" y1="8" x2="12" y2="16" />
                <line x1="8" y1="12" x2="16" y2="12" /></svg>
            </span>
            {{ 'HOME.addPost' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="slider-form">
      <!-- <div class="row m-0 mb-2">
        <div class="col-md-2">
          <button (click)="requests = !requests" [class.active]="!requests" class="btn btn-offres full-width">
            {{ 'BUTTONS.offres' | translate }}
          </button>
        </div>
        <div class="col-md-2">
          <button (click)="requests = !requests" [class.active]="requests" class="btn btn-offres full-width">
            {{ 'BUTTONS.demandes' | translate }}
          </button>
        </div>
      </div> -->
      <lokatout-search-form></lokatout-search-form>
    </div>
  </div>
  <div class="col-lg-4">
    <app-svg-map></app-svg-map>
  </div>
</div>
