import { Component, NgZone, OnInit, Inject } from '@angular/core';
import { Router, Params } from '@angular/router';
@Component({
  selector: 'lokatout-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  requests = false;
  showCategories = false;
  keyWords = '';
  place = '';
  selectedCategory: any;



  constructor(
    public router: Router
  ) { }
  ngOnInit(): void {

  }


}
