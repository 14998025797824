import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'lokatout-annonce-box',
  templateUrl: './adv-box.component.html',
  styleUrls: ['./adv-box.component.scss']
})
export class AdvBoxComponent implements OnInit {


  @Input() annonce;
  @Input() feePercent = 0;

  url = environment.Api_Url;
  annonceMedia = environment.annonceMedia;

  constructor() { }

  ngOnInit(): void {

  }





  getSpecificPrice() {

    let price = 0;
    const indexDefaultPrice = this.annonce?.prices?.findIndex(x => x.isDefault === true);

    if (indexDefaultPrice > 0) {
      const defaultPrice = this.annonce?.prices[indexDefaultPrice].total;
      price = ((this.feePercent / 100) * defaultPrice) + defaultPrice;
    } else {
      const defaultPrice = this.annonce?.prices[0]?.total;
      price = ((this.feePercent / 100) * defaultPrice) + defaultPrice;
    }
    return price;
  }



  getSpecificPeriod() {

    const indexDefaultPrice = this.annonce.prices.findIndex(x => x.isDefault === true);

    if (indexDefaultPrice > 0) {
      return this.annonce.prices[indexDefaultPrice].period;
    }
    return this.annonce.prices[0].period;
  }

}
