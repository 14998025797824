<div class="category-item" *ngIf="category">
  <div class="category-box">
    <div class="category-cover">
      <a href="javascript:void(0)">
        <div *ngIf="!category.picture?.path" [style.backgroundImage]="'url(assets/img/not-found.jpg)'"
          class="img-fluid full-width"></div>
        <div *ngIf="category.picture?.path" [style.backgroundImage]="'url('+ url + category.picture.path +')'"
          class="img-fluid full-width"></div>
      </a>
    </div>
    <div class="category-caption">
      <a routerLink="/annonces/list" [queryParams]="{category:category._id}" class="text-light-black">
        <div class="title-box">
          <h6 class="category-title">{{category.name}} </h6>
        </div>
      </a>
    </div>
  </div>
</div>
