import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lokatout-annonces-list',
  templateUrl: './advers-list.component.html',
  styleUrls: ['./advers-list.component.scss']
})
export class AdversListComponent implements OnInit {


  @Input() annonces;
  @Input() feePercent = 0;

  SlideMenuConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    margin: 10,
    autoplaySpeed: 2000,
    dots: false,
    arrows: true,
    nextArrow: '<button class="btn btn-round next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg></button>',
    prevArrow: '<button class="btn btn-round prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"/></svg></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  constructor() { }

  ngOnInit(): void {
  }

}
